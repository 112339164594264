import $ from 'jquery'
import 'slick-carousel'

$(document).ready( initFeaturesSlider )
if (window.acf) {
  window.acf.addAction('render_block_preview/type=features-slider', initFeaturesSlider)
}

function initFeaturesSlider(){
  if ($('.js-features-slider').length) {
    console.log('initFeaturesSlider')
    $('.js-features-slider').slick({
      arrows: false,
      dots: true,
      slidesToShow: 1,
      customPaging: function(slick,index) {
        console.log(slick.$slides.eq(index))
        var slideName = slick.$slides.eq(index).find('.slider-item').attr('data-slide')
        return `<div class="category-button">${slideName}</div>`
    }
    });
  }
}
