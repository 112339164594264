import $ from 'jquery'

$(document).ready(initAccordionTable)
if (window.acf) {
  window.acf.addAction('render_block_preview/type=accordion-table', initAccordionTable)
}

function initAccordionTable() {
  let index = 1;
  $('.accordion-table .accordion-parent').each(function () {
    let el = $(this);
    let colspan = el.children().length;
    let first = el.children().first();
    let firstText = first.text();
    first.html('<button id="accordion-btn-'+index+'" class="accordion-trigger" aria-expanded="false" aria-controls="accordion-panel-'+index+'"><span class="icon"></span>'+firstText+'</button>');
    let child = el.next('.accordion-child');
    child.children('td').attr('colspan', colspan);
    child.attr('id', 'accordion-panel-'+index);
    child.attr('aria-labelledby', 'accordion-btn-'+index);
    index++;
  });
  $('.accordion-table .accordion-trigger').click(function () {
    let el = $(this);
    if (el.parent().parent().hasClass('active')) {
      el.attr('aria-expanded', 'false');
    } else {
      el.attr('aria-expanded', 'true');
    }
    el.parent().parent().next().toggle();
    el.parent().parent().toggleClass('active');
  });
}
