import $ from 'jquery';
import 'slick-carousel';

$(document).ready(initAnimatedCards);
if (window.acf) {
    window.acf.addAction('render_block_preview/type=animated-cards', initAnimatedCards);
}

function initAnimatedCards() {
    let currentCardIndex = 0; // Initially card 1 is active
    let isMobile = window.innerWidth < 900; // Track initial screen size

    function resetToFirstCard(isMobile) {
        const allCards = document.querySelectorAll('.card');
        const links = document.querySelectorAll('.animated-card__link'); // Target the correct links

        // Remove 'active' class from all links and cards
        links.forEach(link => link.classList.remove('active'));
        allCards.forEach(card => card.classList.remove('active', 'hidden')); // Remove 'active' and 'hidden' from all cards

        // Add 'active' class to the first link
        if (links.length > 0) {
            links[0].classList.add('active'); // Ensure the first link is marked as active
        }

        // Reset all cards
        allCards.forEach((card, index) => {
            card.classList.remove('up', 'down', 'left', 'right');
            card.style.marginTop = '';
            card.style.transform = ''; // Reset transformations

            const scaleValue = 1 - index * 0.05; // Scaling for each card
            if (isMobile) {
                card.style.transform = `translateX(${index * 25}px) scale(${scaleValue})`; // Apply X-axis shift for mobile
            } else {
                const marginTopValue = -index * 30; // Apply margin-top for desktop
                card.style.marginTop = `${marginTopValue}px`;
                card.style.transform = `scale(${scaleValue})`;
            }
        });

        // Set the first card as active
        allCards[0].classList.add('active');
        allCards[0].style.transform = isMobile ? `translateX(0) scale(1)` : `translateY(0) scale(1)`; // Set transform for the first card

        currentCardIndex = 0; // Reset index to the first card
    }

    function applyHiddenClass(card) {
        if (card.style.transform.includes('translateY(-130%)')) {
            card.classList.add('hidden');
        } else {
            card.classList.remove('hidden');
        }
    }

    // This function will only add event listeners once
    function setupEventListeners() {
        const allCards = document.querySelectorAll('.card');
        document.querySelectorAll('a').forEach(link => {
            link.addEventListener('click', function (e) {
                e.preventDefault();
                const cardId = this.getAttribute('data-card');
                let clickedCardIndex;

                // Get the index of the clicked card
                allCards.forEach((card, index) => {
                    if (card.id === cardId) {
                        clickedCardIndex = index;
                    }
                });

                // Remove the 'active' class from all links
                document.querySelectorAll('a').forEach(link => {
                    link.classList.remove('active');
                });

                // Add 'active' class to the clicked link
                this.classList.add('active');

                // Remove classes from all cards to reset their state
                allCards.forEach((card) => {
                    card.classList.remove('up', 'down', 'left', 'right', 'active');
                    card.style.marginTop = ''; // Reset margin-top
                });

                // Detect current mobile/desktop state
                const isMobile = window.innerWidth < 900;

                // Behavior for screens larger than 900px (vertical sliding)
                if (!isMobile) {
                    // Move forward (higher index)
                    if (clickedCardIndex > currentCardIndex) {
                        allCards.forEach((card, index) => {
                            if (index < clickedCardIndex) {
                                card.classList.add('up'); // Slide previous cards up
                                card.style.transform = `translateY(-130%)`; // Slide up
                                applyHiddenClass(card); // Add 'hidden' class if translateY(-130%)
                            } else if (index === clickedCardIndex) {
                                card.classList.add('active'); // Show the clicked card as active
                                card.style.transform = `translateY(0) scale(1)`; // Full size for active card
                                card.style.marginTop = `0px`; // Active card should have margin-top: 0
                                card.classList.remove('hidden'); // Remove 'hidden' since it's active
                            }
                        });
                    }

                    // Move backward (lower index)
                    if (clickedCardIndex < currentCardIndex) {
                        allCards.forEach((card, index) => {
                            if (index > clickedCardIndex) {
                                card.classList.add('down'); // Slide next cards down
                            } else if (index === clickedCardIndex) {
                                card.classList.add('active'); // Make the clicked card active
                                card.style.transform = `translateY(0) scale(1)`; // Full size for active card
                                card.style.marginTop = `0px`; // Active card should have margin-top: 0
                                card.classList.remove('hidden'); // Remove 'hidden' since it's active
                            }
                        });
                    }

                    // Apply progressive scaling and margin-top to all cards behind the active one
                    allCards.forEach((card, index) => {
                        if (index > clickedCardIndex) {
                            const scaleValue = 1 - (index - clickedCardIndex) * 0.05; // Decrease scale by 0.05 for each card behind
                            card.style.transform = `translateY(0) scale(${scaleValue})`;

                            const marginTopValue = -(index - clickedCardIndex) * 30; // Decrease margin-top by 30px for each card behind
                            card.style.marginTop = `${marginTopValue}px`; // Apply margin-top based on position
                            applyHiddenClass(card); // Check and apply hidden class after transform
                        }
                    });

                    // Behavior for screens smaller than 900px (horizontal sliding)
                } else {
                    // Move forward (higher index)
                    if (clickedCardIndex > currentCardIndex) {
                        allCards.forEach((card, index) => {
                            if (index < clickedCardIndex) {
                                card.classList.add('left'); // Slide previous cards left
                                card.style.transform = `translateX(-130%)`; // Slide cards left
                                card.classList.add('hidden'); // Add 'hidden' for horizontal slide
                            } else if (index === clickedCardIndex) {
                                card.classList.add('active'); // Show the clicked card as active
                                card.style.transform = `translateX(0) scale(1)`; // Full size for active card
                                card.classList.remove('hidden'); // Remove 'hidden' since it's active
                            }
                        });
                    }

                    // Move backward (lower index)
                    if (clickedCardIndex < currentCardIndex) {
                        allCards.forEach((card, index) => {
                            if (index > clickedCardIndex) {
                                card.classList.add('right'); // Slide next cards right
                            } else if (index === clickedCardIndex) {
                                card.classList.add('active'); // Make the clicked card active
                                card.style.transform = `translateX(0) scale(1)`; // Full size for active card
                                card.classList.remove('hidden'); // Remove 'hidden' since it's active
                            }
                        });
                    }

                    // Apply progressive scaling and shifting on X-axis for all cards behind the active one
                    allCards.forEach((card, index) => {
                        if (index > clickedCardIndex) {
                            const scaleValue = 1 - (index - clickedCardIndex) * 0.05; // Decrease scale by 0.05 for each card behind
                            card.style.transform = `translateX(${(index - clickedCardIndex) * 25}px) scale(${scaleValue})`; // Shift by 25px on X-axis
                        }
                    });
                }

                // Update the current card index
                currentCardIndex = clickedCardIndex;
            });
        });
    }

    function handleResize() {
        const newIsMobile = window.innerWidth < 900;

        // Only reset when switching between mobile (<900px) and desktop (>=900px)
        if (newIsMobile !== isMobile) {
            resetToFirstCard(newIsMobile);
            isMobile = newIsMobile; // Update the state
        }
    }

    // Initialize on page load
    resetToFirstCard(isMobile);
    setupEventListeners(); // Only call this once on page load

    // Add resize event listener
    window.addEventListener('resize', handleResize);
}

$('.js-animated-cards').on('setPosition', function () {
    var maxHeight = 0;
    $('.js-animated-cards .slick-slide').each(function () {
        var slideHeight = $(this).outerHeight();
        if (slideHeight > maxHeight) {
            maxHeight = slideHeight;
        }
    });
    $('.js-animated-cards .slick-slide').css('height', maxHeight + 'px');
});

setTimeout(function () {
    $('.animated-cards--divs').fadeIn(300);
});
