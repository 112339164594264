import $ from 'jquery'

const index1 = 82.06;
const index2 = 68.50;
const index3 = 198.62;
const index4 = 173.82;

const index6 = 0.15;

function calculateData(input1, input2, input3) {
  // console.log('the data should be calculated');
  var calculatedField1, calculatedField2, calculatedField3, calculatedField4;
  var output1, output2;

  if (input3 === 'bank' || input3 === 'cu') {
    calculatedField1 = input1 * input2 * index1;
    calculatedField2 = input1 * input2 * index1;
    calculatedField3 = input1 * index6 * index2;
    calculatedField4 = 10000 + (input1 * input2 * 40); // in $
  }
  else {
    calculatedField1 = input1 * input2 * index1;
    calculatedField2 = input1 * input2 * index3;
    calculatedField3 = input1 * index6 * index4;
    calculatedField4 = 10000 + (input1 * input2 * 40); // in $
  }

  output1 = calculatedField1 + calculatedField2 + calculatedField3;
  output2 = output1 / calculatedField4;

  console.log(output2);

  console.log(output1.length);
  if (parseInt(output1) > 10000000 && parseInt(output1) < 1000000000) {
    jQuery("#roiResults1").removeClass('size48 size58').addClass('size58');
  }
  else if (parseInt(output1) >= 1000000000) {
    console.log('should set this')
    jQuery("#roiResults1").removeClass('size48 size58').addClass('size48');
  }

  jQuery("#roiResults1").text('$' + numberWithCommas(output1));
  jQuery("#roiResults2").text(parseFloat(output2).toFixed(1) + "x");
}

function numberWithCommas(x) {
  var roundedNumber = parseInt(x);
  var parts = roundedNumber.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}


jQuery("#js-roi-calculate").on('click', function (e) {
  let input1 = jQuery('#numberOfFiles').val();
  let input2 = 1; // was range slider
  let input3 = jQuery('#institution').val();


  if (input1 === '' || input2 === '' || input3 === '') {
    return;
  }
  else if (input1 === 0 || input1 === '0' || input2 === 0) {
    // console.log('dont calculate anything');
    jQuery("#roiResults1").text('$0.00');
    jQuery("#roiResults2").text('0.00x');
    return;
  }
  else {
    if (input3 === 'bank' || input3 === 'cu' || input3 === 'imb') {
      calculateData(input1, input2, input3);
    }
  }
});
